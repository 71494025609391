<script setup>
// const items = [
//   {
//     name: "Sophia Martinez",
//     title: "Lead designer, Avalon",
//     image:
//       "https://images.generated.photos/TdA_3xKb49DtgqfPPu5REnQFiALErCu6i3M2I_1AqIA/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDAwNTU5LmpwZw.jpg",
//     body: "I've been in the design game for a while now and this kit is one of the best. It's made me fall in love with designing all over again. It's just brilliant.",
//   },
//   {
//     name: "Caroline Lee",
//     title: "Senior Graphic Designer, Echo",
//     image:
//       "https://images.generated.photos/mp3gkB9z2wwhyySWzQJMegNbvvLV6HbKQX1_h6BEYAc/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDAwOTI5LmpwZw.jpg",
//     body: "I'm a newbie designer and this kit is just perfect. I've learnt so much and my work looks amazing now. A big thumbs up!",
//   },
//   {
//     name: "Tyler Otwell",
//     title: "CTO, Apple",
//     image:
//       "https://images.generated.photos/vOkveSlBohR880A8z67aLHImhPr7UHdnXg06pifYvZ4/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDAyNjg4LmpwZw.jpg",
//     body: "This design kit is a game changer! My designs look better and I get things done faster. Totally recommending it to my mates!",
//   },
//   {
//     name: "Jake Harris",
//     title: "Thompson Creative",
//     image:
//       "https://images.generated.photos/d_r3VzW3lO5ZsdaHhoVX7msWku7YIrqP4Qn1yozJU54/rs:fit:256:256/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MDAyMTQ4LmpwZw.jpg",
//     body: "I'm a newbie designer and this kit is just perfect. I've learnt so much and my work looks amazing now. A big thumbs up!",
//   },
// ]

const items = [
    {
        "title": "@KhurramBinKamal",
        "content": "My first IDO on Oxbull was in March 2021 with Etna. I had participated in ICOs in 2017 but many were rugs. Then, Oxbull introduced me to the concept of IDO on proper launchpads, and it changed everything for me in terms of not only profit but good project identification. Love you Oxbull #ido"
    },
    {
        "title": "@White_obj",
        "content": "Been with @Oxbull since its inception in April 2021 and contributed to a lot of IDOs & INOs, and most of them have brought in good ROIs. But the one thing that has kept me growing with this team has always been their ethics and discipline in being transparent in all things."
    },
    {
        "title": "@CryptoRaho",
        "content": "I'm here since day 1 and never sold OXB and not gonna sell because I'm lucky and happy to be here. I've seen this team develop from day 1 and knowing it was their part-time job, the progress is enormous."
    },
    {
        "title": "@cyhndr",
        "content": "It was a great journey with Oxbull when you see a team never stop building and developing every day. Also, with their great community, you know why you will never lose... always happy to be here and thanks for the great people I have met here."
    },
    {
        "title": "@berrin_bozogluu",
        "content": "Oxbull is among the best and most profitable launchpads I've ever seen. The projects it brought in during the bull period were truly enormous and people were crushing each other to get in, but the Oxbull stakers were very comfortable."
    },
    {
        "title": "@Bing4Chiling",
        "content": "From the beginning of Oxbull in May 2021, I have contributed to many IDOs, and the majority of them have generated profitable returns. But their ethics and discipline in being truthful in all things have always been the one thing that has kept me progressing with this team."
    },
    {
        "title": "@Luansptub",
        "content": "Build, simple rule, not impacted by market. Focus on people's experiences and always make users feel they put the money in the right place. Good job team!!"
    },
    {
        "title": "@YUGEbears",
        "content": "I have experience with some launchpads and most require high investment and launch projects without strict criteria. Until I met Oxbull, where we can invest with lower amounts and what's better, the projects launched are always of high quality."
    },
    {
        "title": "@HoteDonki4",
        "content": "OXB captured me as a result of the following: OXB is evidence that anything is possible with tenacity and diligence, no matter how challenging the task. Commitment and cooperation are now guiding principles in life. I feel as though I have joined an exceptionally strong family."
    },
    {
        "title": "@kkholimi",
        "content": "Have been with Oxbull for 1.5 years, and it has been a rewarding ride with this technically & entrepreneurial team. Super talented. They know what they are doing. Yes, surely Oxbull has made an impact on me & my friends. Thank you so much, Oxbull."
    },
    {
        "title": "@ParabolicCowboy",
        "content": "I’ve been in hundreds of projects, and Oxbull is one of the very few that I’ve never exited. It’s got a sentimental value to me. #OXB taught me in my worst & rewarded me in my best times in crypto. From T3 to T2, & now my target is to get to Tier 1."
    },
    {
        "title": "@KriptoCek",
        "content": "The platform, which is constantly improving itself with its hardworking team, is innovative enough for me."
    },
    {
        "title": "@Crypto_Bera",
        "content": "I'm a 20 month old #oxbull investor, I didn't care if the #oxb price went up or down because #oxbull was the launch pad that gave me the most returns with the projects it brought in. Keep going with the oxbull. Because oxbul team always works for the best."
    },
    {
        "title": "@iMartynas",
        "content": "I'm here since day 1 and never sold OXB and not gonna sell because I'm lucky and happy to be here. I've seen this team develop from day 1 and knowing it was their part-time job the progress is enormous. This is my best find in the whole crypto world. Trust the process."
    },
    {
        "title": "@Sass13877725",
        "content": "OXB got me because of the following things: dedication and teamwork have become life guidelines. I feel I have become part of an extraordinary 'family'. OXB is proof that with persistence and hard work, you can achieve anything, no matter how difficult. #OXB, #KWAI."
    },
    {
        "title": "@jetpippo",
        "content": "\"The journey of a thousand miles begins with a single step.\" Mine began with OXB and will continue so long as I can walk."
    },
    {
        "title": "@pentagram162",
        "content": "There are lots of launchpads. However, when it comes to OxBull, trust is more important than the launchpad. I have owned OxBull and KWAI(OXS) for a long time, it doesn't matter if the price has increased or decreased, I am a member of the OxBull family."
    },
    {
        "title": "@YucesoyBekir",
        "content": "Oxbull is the best launchpad that ever existed. Never disappointed even if a coin is below the IDO price. I've had any issues with Oxbull and I'm heavy on launchpads and tried probably over 30 launchpads in the last bull run. I'm still subscribed and I'm here and will stand with you."
    },
    {
        "title": "@Pingpong5959",
        "content": "The journey was fantastic with @Oxbull5. I must admit that I feel fortunate and grateful to have been a part of this family since 2021, when we welcomed a new, wonderful member. OxBull5 provided us with some of the best IDOs we have ever seen."
    }
]

</script>

<template>
  <div class="relative flex w-screen items-center">
    <div class="relative flex max-w-[100vw] overflow-hidden py-5">
      <div class="flex w-max animate-marquee [--duration:200s] hover:[animation-play-state:paused]">
        <div v-for="item in [...items, ...items]" :key="item.title" class="h-full px-2.5">
          <div
            class="relative h-full w-[28rem] rounded-2xl border border-white/5 bg-white/5 px-8 py-6 backdrop-blur-sm">
            <div class="pb-4 font-light text-white/75">{{ item.content }}</div>

            <div class="mt-auto flex items-center gap-4">
              <!-- <img :src="item.image" class="h-9 w-9 rounded-full" /> -->

              <div class="flex flex-col text-sm">
                <div class="text-white">{{ item.title }}</div>

                <!-- <div class="text-white/75">{{ item.title }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
