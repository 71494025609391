<template>
  <!--<div ref="wrapper" class="w-full flex justify-center items-center space-x-2 p-2 max-w-[100vw] overflow-hidden overflow-x-auto">
    <div class="bg-black rounded-full p-1 px-3" v-for="i in 10">
      {{ text }}
    </div>
  </div>-->

  <div class="w-full max-w-[100vw] overflow-hidden  py-1" :class="[theme=='black'?'bg-white':'bg-turquoise-500']">
    <ClientOnly>
      <vue3-seamless-scroll :step="0.25" :direction="direction" :single-line="true" :list="textList" class="w-full overflow-hidden">
        <div v-for="i in 10" class="px-1">
          <div :class="[theme=='black'?'bg-zinc-900 text-white':'',theme=='green'?'bg-gradient-to-r from-zinc-900 to-zinc-900 text-turquoise-500':'']" class=" rounded-full py-2 px-6 font-brand font-bold" >{{ text }}</div>
        </div>
          </vue3-seamless-scroll>
    </ClientOnly>
  </div>
</template>

<script setup>
const props = defineProps(["text","direction","theme"]);
const { text, direction,theme } = toRefs(props);
const textList = computed( () => {
  let _texts = []
  for (let i = 0; i < 10; i++) {
    _texts.push(text.value)
  }
  return _texts
})

import { Vue3SeamlessScroll } from "vue3-seamless-scroll";


</script>

<style></style>
